import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import {
  AlertController,
  LoadingController, ModalController,
  NavParams
} from "@ionic/angular";
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from "moment";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import readXlsxFile from 'read-excel-file';

@Component({
  selector: 'app-block-for-sale-request-form',
  templateUrl: './block-for-sale-request-form.page.html',
  styleUrls: ['./block-for-sale-request-form.page.scss'],
})
export class BlockForSaleRequestFormPage implements OnInit {
  @Input() data: any = [];
  @Input() actionId: any;
  @Input() actionType: string = null;
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  @ViewChild('selectableRefPersonComponent') selectableRefPersonComponent: IonicSelectableComponent;

  actionList: any[] = []
  selectedActionIdList = [];
  selectedAction = '';
  public formData = {};
  public requestList = []
  public selectedCurrency: any;
  public formDetailList = []
  public formFields = [];
  public exgRate: any;
  toRole = null;
  defaultDetail: any;
  voucherBookId = null;
  public requestVoucherBookId: any;
  companyId = null;
  groupTypeIdList = []
  defaultPartyMasterId: any = null;
  selectedCustomer = null;
  selectedAddressIdList = [];
  listOfAddress = [];
  location: string = ''
  getAllContacts = []
  selectedContactList = []
  getAllCustomers = [];
  selectedParty = ''
  customerContactId = null;
  customerDetails = null;
  holdVoucherBookId = null;
  blockForSaleVoucherBookId = null;
  pageNumber = 0
  noOfRecords = 20
  SVCKAMMappingEmail = null;
  defaultEmailList = null;
  requestCustomerDetail = null;
  loggedInUserEmail = null;
  userDetail = null;
  requestContactDetail = null;
  businessTypeList = []
  refPartyList = []
  refCustomerName = '';
  refDescription = '';
  public listOfPartyTypeFromDefaults = []
  public currencyIdList = []
  public defaultCurrency = []
  public currencyList = []
  setOfTags = []
  selectedTermList = []
  termList = [];
  totalAmount = 0;
  termName = ''
  discountPremium = 0;
  groupTypeList = [{ id: 1, name: 'Client' }, { id: 2, name: 'House' }]
  selectedType = '';
  brokerId = null;
  businessType = '';
  modeOfTransport = '';
  stockController = '';
  brokerList = [];
  transporterDetails = []
  selectedTransportIdList = [];
  columns = [
    { name: "StoneId", prop: "stoneId" },
    { name: "Ct.", prop: "size" },
    { name: "RAPAPORT", prop: "rapPrice" },
    { name: "Memo %", prop: "requestDisc" },
    { name: "Memo Rates/Cts", prop: "requestPrice" },
    { name: "Remarks", prop: "stoneRemarks" },
  ];

  isNext = true;
  constructor(public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public exportAsExcelService: ExportAsExcelService,) { }

  async ngOnInit() {
    if (this.actionType == 'Request From Block For Sale') {
      this.actionList = [{
        id: 1, name: 'Customer Confirm'
      }, {
        id: 2, name: 'Consignment Confirm'
      }, {
        id: 3, name: 'Consignment In Stock'
      }, {
        id: 4, name: 'Sold'
      }]

    } else {
      this.data.filter(el => {
        this.totalAmount += el.size * el.requestPrice;
      })
      this.actionList = [{
        id: 1, name: 'Hold Customer'
      }, {
        id: 2, name: 'Memo Client'
      }, {
        id: 3, name: 'Customer Confirm'
      }, {
        id: 4, name: 'Consignment Confirm'
      }, {
        id: 5, name: 'Consignment In Stock'
      }, {
        id: 6, name: 'Sold'
      },]
    }
    this.companyId = await this.authService.getCompanyId();
    this.businessTypeList = await this.configService.businessType();
    await this.getFormDetail()
    this.getTerm('SS')
    await this.getTransporterDetail('Transport');
    await this.getBrokerDetail('Broker');
    await this.getAllCurrency()
  }

  async getFormDetail() {
    this.formDetailList = []
    let cartActionDetail = await this.configService.getCartActionList();
    this.formFields = cartActionDetail.formFields;
    let partyTypeList = cartActionDetail.partyTypeList;
    partyTypeList.forEach(el => {
      if (el.name == 'Client') {
        el.actionList.forEach(item => {
          if (item.actionName == this.selectedAction) {
            if (!!item.allowPermission && item.allowPermission.length > 0) {
              item.allowPermission.forEach(el => {
                let indexOfArr = this.formFields.findIndex(item => item.id == el)
                if (indexOfArr > -1) {
                  if (this.formFields[indexOfArr].keyName == 'shippingDate') {
                    this.formData['shippingDate'] = moment().format("YYYY-MM-DD HH:mm:ss")
                  }
                  if (this.formFields[indexOfArr].keyName == 'shipmentType') {
                    this.formData['shipmentType'] = 'Next Shipment'
                  }
                  if (this.formFields[indexOfArr].keyName == 'purpose') {
                    this.formData['purpose'] = 'Client Order'
                  }
                  this.formDetailList.push(this.formFields[indexOfArr])
                }
              })
            }
          }
        }
        )
      }
    })
    console.log(this.formDetailList)

  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.disableScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.disableScroll(event);
  }

  disableScroll(event: any) {
    if (event.srcElement.type === "number")
      event.preventDefault();
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.actionId
    );
    let resp = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", resp.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", resp.error);
    } else {
      let dataValue = resp.data;
      this.defaultDetail = dataValue.map;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
        if (!!dataValue.map.requestVoucherBook) {
          this.requestVoucherBookId = dataValue.map.requestVoucherBook
        }
        if (this.selectedAction == 'Hold Customer' && !!dataValue.map["Hold Customer"]) {
          let dataJson = JSON.parse(dataValue.map["Hold Customer"]);
          this.voucherBookId = dataJson.voucherBookId;
        }
        // if (!!dataValue.map["Block For Sale"]) {
        //   let dataJson = JSON.parse(dataValue.map["Block For Sale"]);
        //   this.requestVoucherBookId = dataJson.voucherBookId;
        // }
        let dataJson = JSON.parse(this.defaultDetail[this.selectedAction])
        if (!!dataJson.partyType && dataJson.partyType.length > 0) {
          this.listOfPartyTypeFromDefaults = dataJson.partyType;
        }
      }
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "customer") {
      console.log(this.formData)
      if (idList.length > 0) {
        this.selectedCustomer = idList[0];
        this.formData['partyMasterId'] = idList[0];
        this.getAddressesByPartyId(idList[0])
        this.getAllCustomerContact();
        this.getCustomerDetails(idList[0]);
      } else {
        delete this.formData['partyMasterId']
        delete this.formData['customerContactId']
        this.selectedContactList = []
        this.selectedCustomer = null;
        this.customerContactId = null;
      }
    } else if (property == "contacts") {
      if (idList.length > 0) {
        this.formData['customerContactId'] = idList[0];
        this.customerContactId = idList[0];
      } else {
        delete this.formData['customerContactId']
        this.customerContactId = null;
      }
    }
    else if (property == 'selectedAction') {
      if (idList.length > 0) {
        delete this.formData['partyMasterId']
        delete this.formData['customerContactId']
        this.selectedContactList = []
        this.selectedCustomer = null;
        this.customerContactId = null;
        this.selectedAction = obj[0].name;
        this.getFormDetail()
        this.getDefaultSetting()
      } else {
        this.selectedAction = '';
      }
    }
    else if (property == 'groupType') {
      delete this.formData['partyMasterId']
      delete this.formData['customerContactId']
      this.selectedContactList = []
      this.selectedCustomer = null;
      this.customerContactId = null;
      if (idList.length > 0) {
        this.selectedType = obj[0].name;
      } else {
        this.selectedType = '';
      }
    } else if (property == "address") {
      if (idList.length > 0) {
        this.formData['location'] = obj[0].address;
        this.formData['address'] = obj[0].address;
        this.listOfAddress.filter(el => {
          if (el.id == idList[0]) {
            this.formData['addSrNo'] = !!el.addSrNo ? el.addSrNo : null;
          }
        })
      } else {
        delete this.formData['location']
        delete this.formData['address']
        delete this.formData['addSrNo']
      }
    } else if (property == "tags") {
      if (idList.length > 0) {
        this.setOfTags = idList;
        this.formData['setOfTags'] = idList;
      } else {
        delete this.formData['setOfTags']
        this.setOfTags = [];
      }
    } else if (property == 'selectedBroker') {
      if (idList.length > 0) {
        this.formData['brokerId'] = idList[0];
        this.brokerId = idList[0];
      } else {
        delete this.formData['brokerId']
        this.brokerId = null;
      }
    } else if (property == 'refParty') {
      if (idList.length > 0) {
        this.formData['customerReferenceId'] = idList[0];
      } else {
        delete this.formData['customerReferenceId']
      }
    } else if (property == 'selectedCurrency') {
      if (idList.length > 0) {
        this.currencyList.filter(el => {
          if (el.id == idList[0]) {
            this.formData['currencyRate'] = 0;
            this.formData['spreadRate'] = 0;
          }
        })
        this.formData['currencyMasterId'] = idList[0];
        this.selectedCurrency = obj[0].name;
        // this.formData['currency'] = obj[0].name
        // this.getLatestRateByCurrencyName(obj[0].name)
      } else {
        delete this.formData['currencyMasterId']
      }
    }
    else if (property == 'businessType') {
      if (idList.length > 0) {
        this.formData['businessType'] = idList[0];
        this.businessType = obj[0].name;
      } else {
        delete this.formData['businessType']
        this.businessType = null;
      }
    }
    else if (property == 'transportMode') {
      if (idList.length > 0) {
        this.formData['modeOfTransport'] = obj[0].partyName
        this.modeOfTransport = obj[0].partyName;
      } else {
        delete this.formData['modeOfTransport']
        this.modeOfTransport = null;
      }
    } else if (property == "salePerson") {
      if (idList.length > 0) {
        this.formData['stockController'] = idList[0];
        this.stockController = idList[0];
      } else {
        delete this.formData['stockController']
        this.stockController = null;
      }
    }


    else if (property == "term") {
      if (idList.length > 0) {

        this.termList.filter(el => {
          if (el.id == idList[0]) {
            this.discountPremium = el.discount
            this.termName = el.name;
            this.formData['termName'] = this.termName
          }
        })
      } else {
        delete this.formData['termName'];
        this.termName = null;
        this.discountPremium = 0;
      }

    }
  }

  applyDiscount(element, discount) {
    element.requestPrice = Number((element.requestPrice - (element.requestPrice * Number(discount) / 100)).toFixed(2));
    element.requestAmt = Number((element.requestPrice * (element.size || element.stoneCts || element.cts)).toFixed(2));
    element.requestDisc = element.rapPrice ? Number(((element.rapPrice - element.requestPrice) * 100 / element.rapPrice).toFixed(2)) : (element.requestDiscount ? Number(element.requestDiscount).toFixed(2) : 0);
  }

  createRequestObject(stone, formData) {
    const size = stone.size || stone.stoneCts || stone.cts;
    const rapPrice = Number(stone.rapPrice) || null;
    const requestPrice = Number(stone.requestPrice) || Number(stone.price) || 0;
    const requestDiscount = rapPrice ? Number((((rapPrice - requestPrice) * 100) / rapPrice).toFixed(2)) : (Number(stone.requestDiscount) || 0);
    const requestAmt = requestPrice ? Number((requestPrice * size).toFixed(2)) : (Number(stone.price * size).toFixed(2) || 0);
    const stoneRemarks = stone.stoneRemarks || formData['remarks'] || null;

    return {
      "stoneId": stone.id.toString(),
      "size": size,
      "rapPrice": rapPrice,
      "requestPrice": requestPrice,
      "requestDiscount": requestDiscount,
      "requestAmt": requestAmt,
      "stoneRemarks": stoneRemarks
    };
  }

  async requestStone() {

    this.exgRate = this.formData['currencyRate'] + (!!this.formData['spreadRate'] ? this.formData['spreadRate'] : 0);
    let setOfStoneIds = []
    this.requestList = []
    let modifyForDisc1Disc2 = JSON.parse(JSON.stringify(this.data));
    if (!!this.formData["disc1"] || !!this.formData["disc2"]) {
      modifyForDisc1Disc2.forEach((el) => {
        let reqPriceN = Number(el.requestPrice) || 0;
        let disc1N = Number(this.formData["disc1"]);
        let disc2N = Number(this.formData["disc2"]);
        let rapPrice = Number(el.rapPrice) || 0;
        let size = el.cts || el.stoneCts || el.size;
        if (!!this.formData["disc1"]) {
          reqPriceN = reqPriceN - ((reqPriceN * disc1N) / 100);
        }
        if (!!this.formData["disc2"]) {
          reqPriceN = reqPriceN - ((reqPriceN * disc2N) / 100);
        }

        el.requestPrice = reqPriceN;
        el.requestAmt = Number((el.requestPrice * size).toFixed(2));
        el.requestDisc = !!rapPrice
          ? Number(
            (((rapPrice - el.requestPrice) * 100) / rapPrice).toFixed(
              2
            )
          )
          : !!el.requestDiscount
            ? Number(el.requestDiscount).toFixed(2)
            : 0;


      });
    }

    modifyForDisc1Disc2.forEach((el, i) => {
      const size = el.cts || el.stoneCts || el.size;
      const rapPrice = el.rapPrice || null;
      const requestPrice = el.requestPrice;
      const requestDiscount = rapPrice
        ? parseFloat((((rapPrice - requestPrice) * 100) / rapPrice).toFixed(2))
        : (el.requestDisc || 0);
      const requestAmt = parseFloat((requestPrice * size).toFixed(2));
      const stoneRemarks = this.formData["remarks"] || el.stoneRemarks || null;
      setOfStoneIds.push(el.id);
      this.requestList.push({
        stoneId: el.id.toString(),
        size: size,
        rapPrice: rapPrice,
        requestPrice: requestPrice,
        requestDiscount: requestDiscount,
        requestAmt: requestAmt,
        srNo: i + 1,
        stoneRemarks: stoneRemarks,
      });
    });

    // // Applying discounts
    // if (this.formData['disc1'] || this.formData['disc2']) {
    //   for (let i = 0; i < this.data.length; i++) {
    //     if (this.formData['disc1']) {
    //       this.applyDiscount(this.data[i], this.formData['disc1']);
    //     }
    //     if (this.formData['disc2']) {
    //       this.applyDiscount(this.data[i], this.formData['disc2']);
    //     }
    //   }
    // }

    // // Iterating over data and creating request list
    // this.data.forEach(stone => {
    //   setOfStoneIds.push(stone.id);
    //   this.requestList.push(this.createRequestObject(stone, this.formData));
    // });
    await this.configService.showLoader();
    console.log(this.requestList)
    let validate = await this.validateControls();
    if (validate) {
      if (this.selectedCurrency == 'Indian Rupee' && !this.formData['currencyRate']) {
        this.configService.showToast("error", 'Please add exchange rate');
        await this.configService.dismiss();
        validate = false;
        return false;
      }

      let res: any;
      let basicObj, concatedObj, jsonObj = {};
      if (this.formData['shippingDate']) {
        this.formData['shippingDate'] = moment(new Date(this.formData['shippingDate'])).format("YYYY-MM-DD")
      }
      if (this.formData['visitTime']) {
        this.formData['visitTime'] = moment(new Date(this.formData['visitTime'])).format("YYYY-MM-DD HH:mm:ss")
      }
      if (!this.formData['disc1']) {
        this.formData['disc1'] = 0
      }
      if (!this.formData['disc2']) {
        this.formData['disc2'] = 0
      }
      let stoneRequestData = {};
      if (this.actionType == 'Request From Block For Sale') {
        const requestJsonList = [];
        const basicObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: 'Unblock For Sale',
          externalStatus: null,
          exgRate: this.exgRate,
          fromUser: null,
        };

        requestJsonList.push({ ...basicObj, ...this.formData });

        const basicObj1 = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.requestVoucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: this.selectedAction,
          externalStatus: null,
          exgRate: this.exgRate,
          fromUser: null,
        };

        requestJsonList.push({ ...basicObj1, ...this.formData });

        console.log(requestJsonList);

        res = await this.httpApiService.stoneUnHoldWithRequest(requestJsonList);
        await this.configService.dismiss();
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          if (!!data.data && data.data.length > 0) {
            await this.configService.showToast("error", data.message);
            await this.exportAsExcelService.exportAsExcelFile(data.data.listOfErrorsAndStoneId, 'Error_Stones')
          } else {
            await this.configService.showToast("error", data.error);
          }
        } else {
          this.selectedAction = ''
          await this.configService.showToast("success", data.message);

          await this.modalCtrl.dismiss('data');
        }
      } else {
        basicObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: this.selectedAction,
          externalStatus: null,
          exgRate: this.exgRate,
          fromUser: null
        };
        concatedObj = Object.assign(basicObj, this.formData)
        jsonObj = concatedObj
        res = await this.httpApiService.saveQueuedAction(jsonObj);
        await this.configService.dismiss();
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          if (!!data.data && data.data.length > 0) {
            await this.configService.showToast("error", data.message);
            await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
          } else {
            await this.configService.showToast("error", data.error);
          }
        } else {
          this.selectedAction = ''
          await this.configService.showToast("success", data.message);

          await this.modalCtrl.dismiss('data');
        }
      }


    } else {
      await this.configService.dismiss();
    }
  }



  async validateControls() {
    let error: Boolean = false;
    console.log(this.formData)
    if (!this.requestList.length) {
      await this.configService.showToast('error', 'Please select stones or refresh page')
      return false
    }
    for (let i = 0; i < this.formDetailList.length; i++) {
      if (this.formDetailList[i].isRequired) {
        if (!this.formData[this.formDetailList[i].keyName] && this.formDetailList[i].keyName != 'spreadRate' && this.formDetailList[i].keyName != 'currencyRate') {
          if ((this.formDetailList[i].keyName == 'shippingCost' && this.formData[this.formDetailList[i].keyName] == 0)) {

          } else {
            await this.configService.showToast('error', this.formDetailList[i].name + ' is required.')
            error = true
            break;
          }
        }
      }
    }
    if (error) {
      return false;
    } else {
      return true;
    }
  }


  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.formData['partyMasterId'] = event.value.id;
      this.formData["shippingCost"] = event.value.shippingCost;
      this.selectedCustomer = event.value.id;
      this.getAddressesByPartyId(event.value.id)
      this.getAllCustomerContact();

      // this.getCustomerDetails(event.value.id);
    } else {
      delete this.formData['partyMasterId'];
      this.selectedCustomer = null;
    }
  }

  async getAddressesByPartyId(id) {
    let res: any;
    res = await this.httpApiService.getAddressesByPartyId(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.listOfAddress = data.data;
      this.selectedAddressIdList = []
      this.listOfAddress = data.data.filter(el => !!el.isActive)
      console.log(this.listOfAddress)
      if (this.listOfAddress.length == 0) {
        delete this.formData['location']
        delete this.formData['address']
        delete this.formData['addSrNo']
      }
      if (this.listOfAddress.length == 1) {
        this.selectedAddressIdList.push({ id: this.listOfAddress[0].id, address: this.listOfAddress[0].address })
        this.location = this.listOfAddress[0].address;
        this.formData['address'] = this.listOfAddress[0].address;
        this.formData['location'] = this.listOfAddress[0].address;
        this.formData['addSrNo'] = this.listOfAddress[0].addSrNo ? this.listOfAddress[0].addSrNo : null;
      }
    }
  }

  async getAllCustomerContact() {
    // await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCustomerContactList(
      this.selectedCustomer
    );
    let data = res.json();
    // await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllContacts = data.data;
      if (this.getAllContacts.length == 1) {
        this.selectedContactList = []
        this.selectedContactList.push({ id: this.getAllContacts[0].id, name: this.getAllContacts[0].name });
        this.customerContactId = this.getAllContacts[0].id;
        this.formData['customerContactId'] = this.getAllContacts[0].id;
        this.requestContactDetail = {
          "name": this.getAllContacts[0].name,
          "contactNo": !!this.getAllContacts[0].contactNo ? this.getAllContacts[0].contactNo : '919000000000',
          "email": this.getAllContacts[0].email,
          "customerMasterId": this.getAllContacts[0].customerMasterId
        }
      } else {
        this.selectedContactList = []
        delete this.formData['customerContactId']
      }
      // this.items = this.listOfParameter;
    }
  }
  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: this.listOfPartyTypeFromDefaults,

    }

    if (this.selectedType == 'Client' && this.selectedAction != 'Hold Customer' && this.selectedAction != 'Block For Sale') {
      json['kyc'] = 'Y'

    }
    res = await this.httpApiService.findByListOfPartyTypePagination(json, this.selectedType, pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.getAllCustomers = data.data.content;
      let dataValueList = data.data.content;
      if (dataValueList.length > 0) {
        for (let i = 0; i < dataValueList.length; i++) {
          dataValueList[i].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`
        }
        this.getAllCustomers = dataValueList;
      } else {
        this.getAllCustomers = []
      }

    }
  }


  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    // if (!this.defaultPartyMasterId) {
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
    // }
  }

  // ion selectable for  reference party name


  // customer reference party

  async getCustomerRefParty(pageNumber, noOfRecords, searchText) {
    let res: any;
    res = await this.httpApiService.findRefCustomerPagination(pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.refPartyList = data.data.content;
    }
  }

  async searchRefCustomer(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getCustomerRefParty(this.pageNumber, 50, searchText);
  }

  onChangeRefCustomer(event: { component: IonicSelectableComponent; value: any }) {
    console.log("onChangeRefCustomer:", event.value);
    if (!!event.value) {
      this.formData['customerReferenceId'] = event.value.id
    } else {
      delete this.formData['customerReferenceId'];
    }
  }

  onAddRefName(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.refCustomerName = '';
    this.refDescription = '';
    // Show form.
    event.component.showAddItemTemplate();
  }


  async createRefCustomer() {
    let res: any;
    if (!!this.refCustomerName) {
      let jsonObj = {
        name: this.refCustomerName,
        description: this.refDescription,
      }
      await this.configService.showLoader();
      res = await this.httpApiService.createRefCustomer(jsonObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.selectableRefPersonComponent.hideAddItemTemplate();
        this.getCustomerRefParty(this.pageNumber = 0, this.noOfRecords, '')
      }

    } else {
      await this.configService.showToast('error', 'Please add name')
    }
  }

  async getAllCurrency() {
    this.currencyIdList = [];
    let res: any;
    res = await this.httpApiService.getCurrencyList();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.currencyList = data.data;
      if (this.currencyList.length > 0) {
        this.currencyList.filter(el => {
          if (el.isDefault) {
            this.formData['currencyRate'] = 0;
            this.formData['spreadRate'] = 0;
            this.currencyIdList = []
            this.formData['currencyMasterId'] = el.id
            this.currencyIdList.push({ id: el.id, name: el.name })
            this.defaultCurrency = el.id;
            // this.getLatestRateByCurrencyName(el.name)
          }
        })
      }
    }
  }

  // Customer Details

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
      if (!!this.customerDetails) {
        this.selectedTermList = []
        this.termList.filter(el => {
          if (el.termDays == this.customerDetails.terms) {
            this.selectedTermList.push({ id: el.id, name: el.name })
            this.termName = el.name;
          }
        })
      }
    }
  }

  //term master 

  async getTerm(transType) {
    let res: any;
    res = await this.httpApiService.findByTransTypeAndOrder(transType);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data;
      let data1 = []
      if (!!dataValueList && dataValueList.length > 0) {
        dataValueList.forEach(el => {
          if (el.isActive == true) {
            data1.push(el)
          }
        })
        this.termList = data1;
      }
      console.log("termList ", this.termList);
    }
  }

  // transporter detail 

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transporterDetails = data.data;
      if (this.transporterDetails.length > 0) {
        this.transporterDetails.filter((item) => {
          if (!!item.isDefaultTransport) {
            this.formData["modeOfTransport"] = item.partyName;
            this.modeOfTransport = item.partyName;
            this.selectedTransportIdList.push({
              id: item.id,
              partyName: item.partyName,
            });
          }
        });
      }
    }
  }

  async getBrokerDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        data.data.filter(el => {
          if (!!el.contactNo) {
            el.brokerWithContact = el.partyName + " - " + el.contactNo
          }
          else if (!!el.tel) {
            el.brokerWithContact = el.partyName + " - " + el.tel
          } else {
            el.brokerWithContact = el.partyName
          }
        })
        let noneData: any;
        data.data.filter((el, i) => {
          if (el.partyName == 'NONE') {
            noneData = el;
            data.data.splice(i, 1);
          }
        })
        data.data.unshift(noneData)
        this.brokerList = data.data
      }
    }
  }

  async validateExSpread(ev) {
    if (ev && ev.detail.value < 0) {
      await this.configService.showToast('error', 'Please add proper value')
    }
  }


  //update price in cart

  async updateValue(event, cell, stoneId) {
    let rowIndex = await this.data.findIndex((x) => x.stoneId === stoneId);

    this.data = [...this.data];
    // this.editing[rowIndex + '-' + cell] = false;
    if (cell == "requestPrice") {
      this.data[rowIndex][cell] = parseFloat(event.target.value);
      console.log(this.data[rowIndex][cell]);
      let errorPrice = "errorPrice";
      if (!!this.data[rowIndex][cell] && this.data[rowIndex][cell] > 0) {
        this.data[rowIndex][errorPrice] = false;
      } else {
        this.data[rowIndex][errorPrice] = true;
        this.data[rowIndex][cell] = 0;
      }
      if (!!this.data[rowIndex].rapPrice) {
        let offerPer =
          ((this.data[rowIndex].rapPrice -
            this.data[rowIndex]["requestPrice"]) *
            100) /
          this.data[rowIndex].rapPrice;
        this.data[rowIndex]["requestDisc"] = offerPer.toFixed(2);
      } else {
        this.data[rowIndex]["requestDisc"] = null;
      }
      // let flag: boolean;
      // if (flag == false || flag == undefined) {
      //   this.isNext = true;
      // }
      if (
        !!this.data[rowIndex]["requestPrice"] &&
        parseFloat(this.data[rowIndex]["requestPrice"]) > 0
      ) {
        this.isNext = true;
        let jsonObj = {
          requestPrice: this.data[rowIndex]["requestPrice"],
          requestDisc: !!this.data[rowIndex]["requestDisc"]
            ? Number.parseFloat(this.data[rowIndex]["requestDisc"]).toFixed(2)
            : 0,
        };

      } else {
        this.isNext = false;
      }
      await this.data.forEach((item) => {
        if (
          parseFloat(item.requestPrice) == null ||
          parseFloat(item.requestPrice) == undefined ||
          parseFloat(item.requestPrice) <= 0
        ) {
          this.isNext = false;
        }
      });
    } else if (cell == "requestDisc") {
      this.data[rowIndex][cell] = parseFloat(event.target.value);

      if (!!this.data[rowIndex].rapPrice) {
        this.data[rowIndex].requestPrice =
          this.data[rowIndex].rapPrice -
          (this.data[rowIndex].rapPrice * this.data[rowIndex].requestDisc) /
          100;
      }
      this.isNext = true;
      let jsonObj = {
        requestPrice: this.data[rowIndex]["requestPrice"],
        requestDisc: this.data[rowIndex]["requestDisc"],
      };
      console.log(jsonObj);
    }
    else if (cell == 'stoneRemarks') {
      this.data[rowIndex].stoneRemarks = event.target.value
    }
    this.data.forEach((item) => {
      if (
        parseFloat(item.requestPrice) == null ||
        parseFloat(item.requestPrice) == undefined ||
        parseFloat(item.requestPrice) <= 0
      ) {
        this.isNext = false;
      }
    });
    console.log(this.data, this.isNext)
  }
}
