import { Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core";

import { Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";

import { IonicSelectableComponent } from "ionic-selectable";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ConfigService } from "src/app/services/config/config.service";
import { ProcessActionComponent } from "../../processAction";
import * as moment from "moment";

@Component({
  selector: "app-stone-selection-rejection",
  templateUrl: "./stone-selection-rejection.component.html",
  styleUrls: ["./stone-selection-rejection.component.scss"],
})
export class StoneSelectionRejectionComponent
  implements OnInit, OnDestroy, ProcessActionComponent {
  @Input() data: any;
  formData = {
    partyId: -1,
    stoneName: "",
    action: "",
    remarks: "",
  };
  payload: any = [];
  selectedParty = [];
  stoneNames = "";
  partyNameList = [];
  historyData = [];
  selection = "Selection";
  isSelected = false;
  pageNumber = 0;
  fromDate = null;
  toDate = null;
  noOfRecords = 10;
  columnsForHistory = [];
  listOfPartyTypeFromDefaults = [
    "Export",
    "Local Sales",
    "Local Purchase",
    "Broker",
    "Import",
  ];
  columnsForCopyData = []
  private searchSubject = new Subject<string>();
  private searchSubcription: any;

  constructor(
    private httpApiService: HttpApiService,
    private authService: AuthenticationService,
    private configService: ConfigService
  ) {
    this.columnsForCopyData = [
      {
        name: "Sr No",
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      { name: "Stone Id", prop: "stoneName" },
      { name: "Remarks", prop: "remarks" },
    ];

    this.columnsForHistory = [
      { name: "Stone Id", prop: "stoneName" },
      { name: "Party Name", prop: "partyName" },
      { name: "Remarks", prop: "remarks" },
      { name: "Action", prop: "action" },
      { name: "Created By", prop: "createdBy" },
      { name: "Created Date", prop: "createdDate" },
    ];
  }


  @HostListener("window:keyup", ["$event"])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      let x = document.activeElement.tagName;
      if (x != "ION-TEXTAREA") {
        if (navigator["clipboard"]) {
          navigator["clipboard"].readText().then((data) => {
            this.getStoneData(data);
          });
        }
      }
    }
  }

  getStoneData(data) {
    let records = data.split("\n");
    this.payload = [];
    for (let i = 1; i < records.length; i++) {
      if (records[i]) {
        let rowArray = records[i].trim().split("\t");
        this.payload.push({
          stoneName: rowArray[0].trim(),
          remarks: rowArray[1],
        });
      }
    }
  }

  ngOnInit() {
    this.searchParty();
    this.searchSubcription = this.searchSubject
      .pipe(
        debounceTime(1000),
        switchMap((searchText: string) => this.onSearch(searchText))
      )
      .subscribe();
  }

  // async searchParty(event: {
  //   component: IonicSelectableComponent;
  //   text: string;
  // }) {
  //   const searchText = event.text.trim().toLowerCase();
  //   await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
  // }

  async searchParty() {
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, "");
  }

  async getAllCustomer(
    pageNumber: number,
    noOfRecords: number,
    searchText: string
  ) {
    const json = {
      listOfType: this.listOfPartyTypeFromDefaults,
    };

    const res = await this.httpApiService.findByListOfPartyTypePagination(
      json,
      "",
      pageNumber,
      noOfRecords,
      searchText
    );

    const data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      const dataValueList = data.data.content;
      if (dataValueList.length > 0) {
        for (let i = 0; i < dataValueList.length; i++) {
          dataValueList[
            i
          ].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`;
        }
        this.partyNameList = dataValueList;
      } else {
        this.partyNameList = [];
      }
    }
  }

  // async onSearch(ev: { component: IonicSelectableComponent; text: string }) {
  //   await this.configService.showLoader();
  //   await this.getAllCustomer(this.pageNumber, this.noOfRecords, ev.text);
  //   await this.configService.dismiss();
  // }

  onSearchTextChange(ev: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    this.searchSubject.next(ev.text);
  }

  async onSearch(text: string) {
    await this.configService.showLoader();
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, text);
    await this.configService.dismiss();
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
  }

  async onOpen(ev: { component: IonicSelectableComponent; value: any[] }) {
    await this.configService.dismiss();
  }

  selectParty(ev: { component: IonicSelectableComponent; value: any }) {
    this.formData.partyId = ev.value.id;
  }


  onRadioChange(event) {
    this.isSelected = true;
    this.selection = event.target.value;
    this.formData.action = this.selection;
  }


  initializeValues() {
    this.formData = {
      partyId: -1,
      stoneName: "",
      action: "",
      remarks: "",
    };
    this.payload = [];
    this.selectedParty = [];
    this.stoneNames = "";
    this.partyNameList = [];
    this.isSelected = false;
    this.selection = "Selection";
  }

  setPayload() {
    this.payload.filter(el => {
      el.action = this.selection;
      el.partyId = this.formData.partyId;
    })
  }

  async onSave() {
    await this.setPayload();
    let res: any = await this.httpApiService.addClientSelectReject(this.payload);
    const data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);

    } else {
      await this.configService.showToast("success", data.message);
      setTimeout(() => {
        this.initializeValues();
      }, 500);
    }
  }

  async findHistory() {
    let payload = {
      fromDate: moment(this.fromDate).format('YYYY-MM-DD'),
      toDate: moment(this.toDate).format('YYYY-MM-DD')
    }
    let res: any = await this.httpApiService.searchHistoryClientSelectionRejection(payload);
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.historyData = data;
    }
  }

  ngOnDestroy(): void {
    this.searchSubcription.unsubscribe();
  }
}
