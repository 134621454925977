import { Injectable } from "@angular/core";
import { ProcessActionItem } from "../../components/processActionItem";
import { RolesComponent } from "../../components/actions/roles/roles.component";
import { StoneInwardComponent } from "../../components/actions/stone-inward/stone-inward.component";
import { ManageActionComponent } from "../../components/actions/manage-action/manage-action.component";
import { UserComponent } from "../../components/actions/user/user.component";
import { MasterComponent } from "../../components/actions/master/master.component";
import { UploadStoneComponent } from "../../components/actions/upload-stone/upload-stone.component";
import { AssortmentComponent } from "../../components/actions/assortment/assortment.component";
import { InventoryManagerComponent } from "../../components/actions/inventory-manager/inventory-manager.component";
import { BoxMasterComponent } from "../../components/actions/box-master/box-master.component";
import { TransferToManufactureComponent } from "../../components/actions/transfer-to-manufacture/transfer-to-manufacture.component";
import { InventoryComponent } from "../../components/actions/inventory/inventory.component";
import { SortComponent } from "../../components/actions/sort/sort.component";
import { VoucherBookComponent } from "../../components/actions/voucher-book/voucher-book.component";
import { SSParameterComponent } from "../../components/actions/ss-parameter/ss-parameter.component";
import { CertificationComponent } from "../../components/actions/certification/certification.component";
import { CustomerComponent } from "../../components/actions/customer/customer.component";
import { ConsignmentComponent } from "../../components/actions/consignment/consignment.component";
import { StonesWithMeComponent } from "../../components/actions/stones-with-me/stones-with-me.component";
import { PriceListUploaderComponent } from "../../components/actions/price-list-uploader/price-list-uploader.component";
import { TransferToSalesMixComponent } from "../../components/actions/transfer-to-sales-mix/transfer-to-sales-mix.component";
import { TransferToSalesSingleComponent } from "../../components/actions/transfer-to-sales-single/transfer-to-sales-single.component";
import { PricingPolicy } from "../../components/actions/pricing-policy/pricing-policy.component";
import { AssortParameterConfigComponent } from "../../components/actions/assort-parameter-config/assort-parameter-config.component";
import { SearchSingleStoneComponent } from "../../components/actions/search-single-stone/search-single-stone.component";
import { SearchMixStoneComponent } from "../../components/actions/search-mix-stone/search-mix-stone.component";
import { StoneWithMeSingleComponent } from "../../components/actions/stone-with-me-single/stone-with-me-single.component";
import { StoneWithMeMixComponent } from "../../components/actions/stone-with-me-mix/stone-with-me-mix.component";
import { ApplyPolicyComponent } from "../../components/actions/apply-policy/apply-policy.component";
import { AssortmentSearchComponent } from "../../components/actions/assortment-search/assortment-search.component";
import { ClearAssortmentStockComponent } from "../../components/actions/clear-assortment-stock/clear-assortment-stock.component";
import { ExportTemplatesComponent } from "../../components/actions/export-templates/export-templates.component";
import { ProgrammeComponent } from "../../components/actions/programme/programme.component";
import { DefaultTemplatesComponent } from "../../components/actions/default-templates/default-templates.component";
import { SearchComponent } from "../../components/actions/search/search.component";
import { PolicyTrackerComponent } from "../../components/actions/policy-tracker/policy-tracker.component";
import { PDComponent } from "../../components/actions/pd/pd.component";
import { StatusMasterComponent } from "../../components/actions/status-master/status-master.component";
import { ParameterMappingMasterComponent } from "../../components/actions/parameter-mapping-master/parameter-mapping-master.component";
import { UpdateParameterComponent } from "../../components/actions/update-parameter/update-parameter.component";
import { CustomerConfirmComponent } from "../../components/actions/customer-confirm/customer-confirm.component";
import { MemoServiceComponent } from "../../components/actions/memo-service/memo-service.component";
import { ConsignmentConfirmComponent } from "../../components/actions/consignment-confirm/consignment-confirm.component";
import { ConsignmentInStockComponent } from "../../components/actions/consignment-in-stock/consignment-in-stock.component";
import { MemoClientComponent } from "../../components/actions/memo-client/memo-client.component";
import { LabExpensesComponent } from "../../components/actions/lab-expenses/lab-expenses.component";
import { ExternalInventoryComponent } from "../../components/actions/external-inventory/external-inventory.component";
import { RecutComponent } from "../../components/actions/recut/recut.component";
import { ObservationComponent } from "../../components/actions/observation/observation.component";
import { PricingSizeMasterComponent } from "../../components/actions/pricing-size-master/pricing-size-master.component";
import { ConversionRateMasterComponent } from "../../components/actions/conversion-rate-master/conversion-rate-master.component";
import { LabConversionRateMasterComponent } from "../../components/actions/lab-conversion-rate-master/lab-conversion-rate-master.component";
import { CompanyComponent } from "../../components/actions/company/company.component";
import { UpdateRfidComponent } from "../../components/actions/update-rfid/update-rfid.component";
import { GeneratePOComponent } from "../../components/actions/generate-po/generate-po.component";
import { DeliveryChallanComponent } from "../../components/delivery-challan/delivery-challan.component";
import { InterCSSPTransferComponent } from "../../components/actions/inter-cssptransfer/inter-cssptransfer.component";
import { AuditCSSPComponent } from "../../components/actions/audit-cssp/audit-cssp.component";
import { ApprovalMemoComponent } from "../../components/actions/approval-memo/approval-memo.component";
import { BoxTransferComponent } from "../../components/actions/box-transfer/box-transfer.component";
import { SingleToMixComponent } from "../../components/actions/single-to-mix/single-to-mix.component";
import { SingleToMixTransferComponent } from "../../components/actions/single-to-mix-transfer/single-to-mix-transfer.component";
import { MixToSingleTransferComponent } from "../../components/actions/mix-to-single-transfer/mix-to-single-transfer.component";
import { PurchaseHistoryComponent } from "../../components/actions/purchase-history/purchase-history.component";
import { RapaportMappingComponent } from "../../components/actions/rapaport-mapping/rapaport-mapping.component";
import { TermMasterComponent } from "../../components/actions/term-master/term-master.component";
import { RapaportPriceListUploaderComponent } from "../../components/actions/rapaport-price-list-uploader/rapaport-price-list-uploader.component";
import { PolicyChainComponent } from "../../components/actions/policy-chain/policy-chain.component";
import { MixToSingleComponent } from "../../components/actions/mix-to-single/mix-to-single.component";
import { PurchaseToSingleComponent } from "../../components/actions/purchase-to-single/purchase-to-single.component";
import { PurchaseInwardComponent } from "../../components/actions/purchase-inward/purchase-inward.component";
import { SalesInventoryComponent } from "../../components/actions/sales-inventory/sales-inventory.component";
import { PurchaseToMixComponent } from "../../components/actions/purchase-to-mix/purchase-to-mix.component";
import { UpdateStoneIdComponent } from "../../components/actions/update-stone-id/update-stone-id.component";
import { InventoryManagerMIXComponent } from "../../components/actions/inventory-manager-mix/inventory-manager-mix.component";
import { InventoryManagerSSComponent } from "../../components/actions/inventory-manager-ss/inventory-manager-ss.component";
import { UpdateBarcodeComponent } from "../../components/actions/update-barcode/update-barcode.component";
import { CustomerHoldComponent } from "../../components/actions/customer-hold/customer-hold.component";
import { LabServicesComponent } from "../../components/actions/lab-services/lab-services.component";
import { CsspTransferMappingComponent } from "../../components/actions/cssp-transfer-mapping/cssp-transfer-mapping.component";
import { SingleMixParamMappingComponent } from "../../components/actions/single-mix-param-mapping/single-mix-param-mapping.component";
import { PendingApprovalComponent } from "../../components/actions/pending-approval/pending-approval.component";
import { RequestApprovalOfOutPlatformComponent } from "../../components/actions/request-approval-of-out-platform/request-approval-of-out-platform.component";
import { UpdateKYCApprovalComponent } from "../../components/actions/update-kycapproval/update-kycapproval.component";
import { WebsiteOfferComponent } from "../../components/actions/website-offer/website-offer.component";
import { ReportsComponent } from "../../components/actions/reports/reports.component";
import { RecutInwardComponent } from "../../components/actions/recut-inward/recut-inward.component";
import { StoneFinalizationComponent } from "../../components/actions/stone-finalization/stone-finalization.component";
import { ElasticSearchComponent } from "../../components/actions/elastic-search/elastic-search.component";
import { PricingCalcComponent } from "../../components/actions/pricing-calc/pricing-calc.component";
import { StoneTrackingComponent } from "../../components/actions/stone-tracking/stone-tracking.component";
import { MixPricingCalcComponent } from "../../components/actions/mix-pricing-calc/mix-pricing-calc.component";
import { SkipAssortmentCriteriaComponent } from "../../components/actions/skip-assortment-criteria/skip-assortment-criteria.component";
import { SoldComponent } from "../../components/actions/sold/sold.component";
import { StockUploadComponent } from "src/app/components/actions/stock-upload/stock-upload.component";
import { UpdatePDFileUploadComponent } from "src/app/components/update-pdfile-upload/update-pdfile-upload.component";
import { UpdateMyScannerBoardComponent } from "src/app/components/update-my-scanner-board/update-my-scanner-board.component";
import { LabPacketServiceMasterComponent } from "src/app/components/actions/lab-packet-service-master/lab-packet-service-master.component";
import { FtpMasterComponent } from "src/app/components/actions/ftp-master/ftp-master.component";
import { StickerPrintingComponent } from "src/app/components/actions/sticker-printing/sticker-printing.component";
import { SoldSearchComponent } from "../../components/actions/sold-search/sold-search.component";
import { MarkAsSoldComponent } from "../../components/actions/mark-as-sold/mark-as-sold.component";
import { UploadRecutRulesComponent } from "../../components/actions/upload-recut-rules/upload-recut-rules.component";
import { FinalizePPriceComponent } from "../../components/actions/finalize-pprice/finalize-pprice.component";
import { DiamondSecurityCheckComponent } from "../../components/actions/diamond-security-check/diamond-security-check.component";
import { PurchaseBulkUploaderComponent } from "../../components/actions/purchase-bulk-uploader/purchase-bulk-uploader.component";
import { UploadInternalLabResultsComponent } from "../../components/actions/upload-internal-lab-results/upload-internal-lab-results.component";
import { PricingHistoryComponent } from "../../components/actions/pricing-history/pricing-history.component";
import { PurchaseSearchComponent } from "../../components/actions/purchase-search/purchase-search.component";

import { EnquiryMasterComponent } from "../../components/actions/enquiry-master/enquiry-master.component";
import { CvdCheckComponent } from "../../components/actions/cvd-check/cvd-check.component";

import { UpdateAvailableForSalesComponent } from "../../components/actions/update-available-for-sales/update-available-for-sales.component";
import { PurchaseConsolidationIncomingstonesComponent } from "../../components/actions/purchase-consolidation-incomingstones/purchase-consolidation-incomingstones.component";
import { PurchaseconsolidationReturnStonesComponent } from "../../components/actions/purchaseconsolidation-return-stones/purchaseconsolidation-return-stones.component";
import { PurchaseUpdatetermsandbankratesComponent } from "../../components/actions/purchase-updatetermsandbankrates/purchase-updatetermsandbankrates.component";
import { PurchaseUpdateInvoiceNoAndSeqNoComponent } from "../../components/actions/purchase-update-invoice-no-and-seq-no/purchase-update-invoice-no-and-seq-no.component";
import { PurchaseconsolidationUpdateparameterviewComponent } from "../../components/actions/purchaseconsolidation-updateparameterview/purchaseconsolidation-updateparameterview.component";
import { UpdateElasticDataComponent } from "../../components/actions/update-elastic-data/update-elastic-data.component";

import { DesignbybudgetComponent } from "src/app/components/actions/designbybudget/designbybudget.component";

import { PdPurchaseStonesComponent } from "src/app/components/actions/pd-purchase-stones/pd-purchase-stones.component";

import { PricingLogsComponent } from "../../components/actions/pricing-logs/pricing-logs.component";

import { StoneGenerationComponent } from "src/app/components/actions/stone-generation/stone-generation.component";
import { RapnetStockUploadComponent } from "src/app/components/actions/rapnet-stock-upload/rapnet-stock-upload.component";
import { ProcessRuleConfigComponent } from "src/app/components/actions/process-rule-config/process-rule-config.component";
import { SvcSearchComponent } from "src/app/components/svc-search/svc-search.component";
import { OtherOfficeStockProcessComponent } from "src/app/components/other-office-stock-process/other-office-stock-process.component";
import { SvcRequestComponent } from "src/app/components/svc-request/svc-request.component";
import { LastTrayComponent } from "src/app/components/actions/last-tray/last-tray.component";
import { WebsiteReportsComponent } from "src/app/components/actions/website-reports/website-reports.component";
import { ManufacturingSearchComponent } from "src/app/components/actions/manufacturing-search/manufacturing-search.component";
import { BlockForSaleComponent } from "src/app/components/actions/block-for-sale/block-for-sale.component";

import { LiveInventoryComponent } from "src/app/components/actions/live-inventory/live-inventory.component";
import { WebsiteUsersComponent } from "src/app/components/actions/website-users/website-users.component";
import { SalesComparisonReportComponent } from "src/app/components/actions/sales-comparison-report/sales-comparison-report.component";
import { WebsiteLastSigninDetailComponent } from "src/app/components/actions/website-last-signin-detail/website-last-signin-detail.component";
import { BatchpartystockuploaderComponent } from "src/app/components/batchpartystockuploader/batchpartystockuploader.component";
import { SalesDashboardComponent } from "src/app/components/actions/sales-dashboard/sales-dashboard.component";
import { SyncOtherOfficeStockComponent } from "src/app/components/actions/sync-other-office-stock/sync-other-office-stock.component";
import { SvcHoldSearchComponent } from "src/app/components/actions/svc-hold-search/svc-hold-search.component";
import { LabResultUploadForOtherOfficeComponent } from "src/app/components/actions/lab-result-upload-for-other-office/lab-result-upload-for-other-office.component";
import { AshokaTextFileComponent } from "src/app/components/actions/ashoka-text-file/ashoka-text-file.component";
import { HoldReportComponent } from "src/app/components/actions/hold-report/hold-report.component";
import { PolishPurchaseInwardReportComponent } from "src/app/components/actions/polish-purchase-inward-report/polish-purchase-inward-report.component";
import { PositionReportComponent } from "src/app/components/actions/position-report/position-report.component";
import { ManualSalesUploadComponent } from "src/app/components/actions/manual-sales-upload/manual-sales-upload.component";
import { PlanandsalesstonesComponent } from "src/app/components/actions/planandsalesstones/planandsalesstones.component";
import { LayoutsComponent } from "src/app/components/actions/layouts/layouts.component";
import { LogisticsApprovalComponent } from "src/app/components/actions/logistics-approval/logistics-approval.component";
import { CurrentParameterSearchComponent } from "src/app/components/actions/current-parameter-search/current-parameter-search.component";
import { ProgramRejectionComponent } from "src/app/components/actions/program-rejection/program-rejection.component";
import { QueuedActionComponent } from "src/app/components/actions/queued-action/queued-action.component";
import { PotentialBuyersComponent } from "src/app/components/actions/potential-buyers/potential-buyers.component";
import { StoneOfferCaptureComponent } from "src/app/components/actions/stone-offer-capture/stone-offer-capture.component";
import { RfidHistoryComponent } from "src/app/components/actions/rfid-history/rfid-history.component";
import { StoneSelectionRejectionComponent } from "src/app/components/actions/stone-selection-rejection/stone-selection-rejection.component";
import { StonePriceTrackingComponent } from "src/app/components/actions/stone-price-tracking/stone-price-tracking.component";
import { ScanViaRFIDComponent } from "src/app/components/actions/scan-via-rfid/scan-via-rfid.component";
import { GlobalSVCSearchComponent } from "src/app/components/actions/global-svcsearch/global-svcsearch.component";
import { UploadManualPricingComponent } from "src/app/components/actions/upload-manual-pricing/upload-manual-pricing.component";
import { WebsiteConfigComponent } from "src/app/components/actions/website-config/website-config.component";
import { BulletinComponent } from "src/app/components/actions/bulletin/bulletin.component";

@Injectable({
  providedIn: "root",
})
export class ProcessService {
  private actionName: string;
  private actionId: number;
  private manageActionName: string;
  private voucherType: string;
  private listOfActionPermissions: any;

  constructor() { }

  setActionName(actionName, actionId, voucherType, listOfActionPermissions) {
    this.actionName = actionName;
    this.actionId = actionId;
    this.voucherType = voucherType;
    this.listOfActionPermissions = listOfActionPermissions;
  }

  getProcessName(): ProcessActionItem[] {
    let result: ProcessActionItem[] = [];
    let comp = this.resolveComponentsName(this.actionName);
    let newItem = new ProcessActionItem(
      comp,
      this.actionName,
      this.actionId,
      this.voucherType,
      this.listOfActionPermissions
    );
    result.push(newItem);
    return result;
  }

  private resolveComponentsName(actionName) {
    if (actionName == "Search") {
      return ElasticSearchComponent;
      // SearchComponent
    } else if (actionName == "Roles") {
      return RolesComponent;
    } else if (actionName == "Inward") {
      return StoneInwardComponent;
    } else if (actionName == "Manage Actions") {
      return ManageActionComponent;
    } else if (actionName == "Users") {
      return UserComponent;
    } else if (actionName == "Parameter Master") {
      return MasterComponent;
    } else if (actionName == "Upload") {
      return UploadStoneComponent;
    } else if (actionName == "Assortment") {
      return AssortmentComponent;
    } else if (actionName == "Inventory Manager") {
      return InventoryManagerComponent;
      //GeneratePOComponent //GeneratePOComponent // InventoryManagerComponent // ApprovalMemoComponent // TermMasterComponent;
    } else if (actionName == "Box Master") {
      return BoxMasterComponent;
    } else if (actionName == "Return Assortment Error Stones") {
      return TransferToManufactureComponent;
    } else if (actionName == "Inventory") {
      return InventoryComponent;
    } else if (actionName == "Sort") {
      return SortComponent;
    } else if (actionName == "SSPD Parameter Config") {
      return SSParameterComponent;
    } else if (actionName == "Certification") {
      return CertificationComponent;
    } else if (actionName == "Manage Voucher Book") {
      return VoucherBookComponent;
    } else if (actionName == "Party Master") {
      return CustomerComponent;
    } else if (actionName == "Consignment") {
      return ConsignmentComponent;
    } else if (actionName == "Price List Uploader") {
      return PriceListUploaderComponent;
    } else if (actionName == "Mix Transfer To Sales") {
      return TransferToSalesMixComponent;
    } else if (actionName == "Single Transfer To Sales") {
      return TransferToSalesSingleComponent;
    } else if (actionName == "Pricing Policy") {
      return PricingPolicy;
    } else if (actionName == "Assort Parameter Config") {
      return AssortParameterConfigComponent;
    } else if (actionName == "Single Search") {
      return SearchSingleStoneComponent;
      //SearchSingleStoneComponent;
    } else if (actionName == "Mix Search") {
      return SearchMixStoneComponent;
    } else if (actionName == "Single With Me") {
      return StoneWithMeSingleComponent;
    } else if (actionName == "Mix With Me") {
      return StoneWithMeMixComponent;
    } else if (actionName == "Apply Pricing Policy") {
      return ApplyPolicyComponent;
    } else if (actionName == "Assortment Stock Search") {
      return AssortmentSearchComponent;
    } else if (actionName == "Clear Assortment Stock") {
      return ClearAssortmentStockComponent;
    } else if (actionName == "Export Templates") {
      return ExportTemplatesComponent;
    } else if (actionName == "With Me") {
      return StonesWithMeComponent;
    } else if (actionName == "Program") {
      return ProgrammeComponent;
    } else if (actionName == "Default Export Template") {
      return DefaultTemplatesComponent;
    } else if (actionName == "Pricing Policy Tracker") {
      return PolicyTrackerComponent;
    } else if (actionName == "PD") {
      return PDComponent;
    } else if (actionName == "Status Master") {
      return StatusMasterComponent;
    } else if (actionName == "Lab Parameter Mapping") {
      return ParameterMappingMasterComponent;
    } else if (actionName == "Update Parameter") {
      return UpdateParameterComponent;
    } else if (actionName == "Memo Client") {
      return MemoClientComponent;
    } else if (actionName == "Customer Confirm") {
      return CustomerConfirmComponent;
    } else if (actionName == "Consignment Confirm") {
      return ConsignmentConfirmComponent;
    } else if (actionName == "Consignment In Stock") {
      return ConsignmentInStockComponent;
    } else if (actionName == "Memo Service") {
      return MemoServiceComponent;
    } else if (actionName == "Lab Expenses") {
      return LabExpensesComponent;
    } else if (actionName == "External Inventory") {
      return ExternalInventoryComponent;
    } else if (actionName == "Recut") {
      return RecutComponent;
    } else if (actionName == "Observation") {
      return ObservationComponent;
    } else if (actionName == "Mix Size Master") {
      return PricingSizeMasterComponent;
    } else if (actionName == "Forex Rate Updater") {
      return ConversionRateMasterComponent;
    } else if (actionName == "Lab Forex Rate Updater") {
      return LabConversionRateMasterComponent;
    } else if (actionName == "Company") {
      return CompanyComponent;
    } else if (actionName == "Update RFID") {
      return UpdateRfidComponent;
    } else if (actionName == "Inter Cssp Transfer") {
      return InterCSSPTransferComponent;
    } else if (actionName == "Audit CSSP") {
      return AuditCSSPComponent;
    } else if (actionName == "Inter Box Transfer") {
      return BoxTransferComponent;
    } else if (actionName == "Single Mixing") {
      return SingleToMixComponent;
    } else if (actionName == "Single To Mix Transfer") {
      return SingleToMixTransferComponent;
    } else if (actionName == "Single Transfer To Assortment") {
      return MixToSingleTransferComponent;
    } else if (actionName == "Mixing History") {
      return PurchaseHistoryComponent;
    } else if (actionName == "RAPAPORT Mapping") {
      return RapaportMappingComponent;
    } else if (actionName == "Term Master") {
      return TermMasterComponent;
    } else if (actionName == "RAPAPORT Price List Uploader") {
      return RapaportPriceListUploaderComponent;
    } else if (actionName == "Pricing Policy Chain") {
      return PolicyChainComponent;
    } else if (actionName == "Mix To Single") {
      return MixToSingleComponent;
    } else if (actionName == "Purchase To Single") {
      return PurchaseToSingleComponent;
    } else if (actionName == "Purchase Inward") {
      return PurchaseInwardComponent;
    } else if (actionName == "Mix Sales Inventory") {
      return SalesInventoryComponent;
    } else if (actionName == "Purchase To Mix") {
      return PurchaseToMixComponent;
    } else if (actionName == "Update StoneId") {
      return UpdateStoneIdComponent;
    } else if (actionName == "Inventory Manager SS") {
      return InventoryManagerSSComponent;
    } else if (actionName == "Inventory Manager MIX") {
      return InventoryManagerMIXComponent;
    } else if (actionName == "Update Barcode") {
      return UpdateBarcodeComponent;
    } else if (actionName == "Customer Hold") {
      return CustomerHoldComponent;
    } else if (actionName == "Lab Services") {
      return LabServicesComponent;
    } else if (actionName == "Cssp Transfer Mapping") {
      return CsspTransferMappingComponent;
    } else if (actionName == "Single Mix Parameter Mapping") {
      return SingleMixParamMappingComponent;
    } else if (actionName == "Pending Transfer Approval") {
      return PendingApprovalComponent;
    } else if (actionName == "Request Approval") {
      return RequestApprovalOfOutPlatformComponent;
    } else if (actionName == "Website Customer Approval") {
      return UpdateKYCApprovalComponent;
    } else if (actionName == "Website Offer") {
      return WebsiteOfferComponent;
    } else if (actionName == "Reports") {
      return ReportsComponent;
    } else if (actionName == "Recut Inward") {
      return RecutInwardComponent;
    } else if (actionName == "Stone Finalization") {
      return StoneFinalizationComponent;
    } else if (actionName == "SS Pricing Calculator") {
      return PricingCalcComponent;
    } else if (actionName == "Mix Pricing Calculator") {
      return MixPricingCalcComponent;
    } else if (actionName == "Stone Tracking") {
      return StoneTrackingComponent;
    } else if (actionName == "Skip Assortment Master") {
      return SkipAssortmentCriteriaComponent;
    } else if (actionName == "Sold") {
      return SoldComponent;
    } else if (actionName == "Stock Upload") {
      return StockUploadComponent;
    } else if (actionName == "Upload PD Parameter") {
      return UpdatePDFileUploadComponent;
    } else if (actionName == "Update Scanner Board Url") {
      return UpdateMyScannerBoardComponent;
    } else if (actionName == "Lab Packet Series Master") {
      return LabPacketServiceMasterComponent;
    } else if (actionName == "Schedule Stock Export") {
      return FtpMasterComponent;
    } else if (actionName == "Sticker Printing") {
      return StickerPrintingComponent;
    } else if (actionName == "Sold Search") {
      return SoldSearchComponent;
    } else if (actionName == "Mark As Sold") {
      return MarkAsSoldComponent;
    } else if (actionName == "Polish Group Master") {
      return UploadRecutRulesComponent;
    } else if (actionName == "Finalize P Price") {
      return FinalizePPriceComponent;
    } else if (actionName == "Diamond Security Check") {
      return DiamondSecurityCheckComponent;
    } else if (actionName == "Party Stock Uploader") {
      return PurchaseBulkUploaderComponent;
    } else if (actionName == "Upload Lab Result For Internal Labs") {
      return UploadInternalLabResultsComponent;
    } else if (actionName == "Pricing History") {
      return PricingHistoryComponent;
    } else if (actionName == "Purchase Search") {
      return PurchaseSearchComponent;
    } else if (actionName == "Enquiry Master") {
      return EnquiryMasterComponent;
    } else if (actionName == "CVD & QC Check") {
      return CvdCheckComponent;
    } else if (actionName == "Update Available For Sale") {
      return UpdateAvailableForSalesComponent;
    } else if (actionName == "Purchase Inward Stones") {
      return PurchaseConsolidationIncomingstonesComponent;
    } else if (actionName == "Return Purchase Stone") {
      return PurchaseconsolidationReturnStonesComponent;
    } else if (actionName == "Update Terms & Bank Rates") {
      return PurchaseUpdatetermsandbankratesComponent;
    } else if (actionName == "Update Invoice No In Purchased Stones") {
      return PurchaseUpdateInvoiceNoAndSeqNoComponent;
    } else if (actionName == "Update Purchased Stone Parameters") {
      return PurchaseconsolidationUpdateparameterviewComponent;
    } else if (actionName == "Sync Data With Search") {
      return UpdateElasticDataComponent;
    } else if (actionName == "Design By Budget") {
      return DesignbybudgetComponent;
    } else if (actionName == "PD Purchase Stones") {
      return PdPurchaseStonesComponent;
    } else if (actionName == "Pricing Logs") {
      return PricingLogsComponent;
    } else if (actionName == "Stone Id Generation") {
      return StoneGenerationComponent;
    } else if (actionName == "Rapnet Stock Upload") {
      return RapnetStockUploadComponent;
    } else if (actionName == "Process Rule Config") {
      return ProcessRuleConfigComponent;
    } else if (actionName == "SVC Single Search") {
      return SvcSearchComponent;
    } else if (actionName == "Other Office Stock") {
      return OtherOfficeStockProcessComponent;
    } else if (actionName == "SVC Request List") {
      return SvcRequestComponent;
    } else if (actionName == "Last Tray Detail") {
      return LastTrayComponent;
    } else if (actionName == "Website Dashboard") {
      return WebsiteReportsComponent;
    } else if (actionName == "Manufacturing Search") {
      return ManufacturingSearchComponent;
    } else if (actionName == "Block For Sale") {
      return BlockForSaleComponent;
    } else if (actionName == "Live Inventory") {
      return LiveInventoryComponent;
    } else if (actionName == "Website Users") {
      return WebsiteUsersComponent;
    } else if (actionName == "Sales Comparison Report") {
      return SalesComparisonReportComponent;
    } else if (actionName == "Website Reports") {
      return WebsiteLastSigninDetailComponent;
    } else if (actionName == "Batch Party Stock Uploader") {
      return BatchpartystockuploaderComponent;
    } else if (actionName == "Sales Dashboard") {
      return SalesDashboardComponent;
    } else if (actionName == "Sync Other Office Stock") {
      return SyncOtherOfficeStockComponent;
    } else if (actionName == "SVC Request List Search") {
      return SvcHoldSearchComponent;
    } else if (actionName == "Lab Result Upload For Other Office Stones") {
      return LabResultUploadForOtherOfficeComponent;
    } else if (actionName == "Ashoka TXT File") {
      return AshokaTextFileComponent;
    } else if (actionName == "Hold Report") {
      return HoldReportComponent;
    } else if (actionName == "Polish Purchase Dashboard") {
      return PolishPurchaseInwardReportComponent;
    } else if (actionName == "Position Report") {
      return PositionReportComponent;
    } else if (actionName == "Manual Sales Upload") {
      return ManualSalesUploadComponent;
    } else if (actionName == "Plan Stones and Sales Stones") {
      return PlanandsalesstonesComponent;
    } else if (actionName == "Logistic Approval Letter") {
      return LogisticsApprovalComponent;
    } else if (actionName == "Inward Report Search") {
      return CurrentParameterSearchComponent;
    } else if (actionName == "Program Rejection") {
      return ProgramRejectionComponent;
    } else if (actionName == "Queued Action") {
      return QueuedActionComponent;
    } else if (actionName == "Potential Buyers") {
      return PotentialBuyersComponent;
    } else if (actionName == "Stone Offer File Upload") {
      return StoneOfferCaptureComponent;
    } else if (actionName == "RFID History") {
      return RfidHistoryComponent;
    } else if (actionName == "Client Selection & Rejection") {
      return StoneSelectionRejectionComponent;
    } else if (actionName == "Stone Price Tracking") {
      return StonePriceTrackingComponent;
    } else if (actionName == "Scan Via RFID") {
      return ScanViaRFIDComponent;
    } else if (actionName == "Global SVC Search") {
      return GlobalSVCSearchComponent;
    } else if (actionName == "Intransit Stone Price Upload") {
      return UploadManualPricingComponent;
    } else if (actionName === "Website Config") {
      return WebsiteConfigComponent;
    } else if (actionName == "Bulletin") {
      return BulletinComponent;
    } else if (actionName == 'Layouts') {
      return LayoutsComponent;
    }
  }
}
